import React, { FC } from "react";
import { auth } from "firebase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import ExitToApp from "@material-ui/icons/ExitToApp";

export const UserDisplay: FC<{
  user: auth.UserCredential["user"];
  onSignOut: () => any;
}> = ({ user, onSignOut }) => {
  if (!user) return null;
  return (
    <List>
      <ListItem style={{ paddingLeft: 16, paddingRight: 24 }}>
        {user.photoURL ? (
          <ListItemAvatar>
            <Avatar src={user.photoURL}></Avatar>
          </ListItemAvatar>
        ) : null}
        <ListItemText
          primary={user.email}
          secondary="Currently Signed In"
          primaryTypographyProps={{ noWrap: true, display: "block" }}
          secondaryTypographyProps={{ noWrap: true, display: "block" }}
        ></ListItemText>
        <ListItemSecondaryAction style={{ right: 0 }}>
          <Tooltip title="Sign in with different account.">
            <IconButton onClick={onSignOut} size="small">
              <ExitToApp />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};
