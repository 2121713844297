import React from "react";
import { AlertProvider } from "@xyo-network/coin-forms/build/lib/components/Alert";
import { BrowserRouter } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import { AuthProvider, useAuthContext } from "./context/firebase";
import ErrorDisplay from "./components/Error";
import Login, { SignUpLink } from "./components/Login";
import SignUp, { LoginLink } from "./components/Signup";
import Confirm, { ConfirmScopes } from "./components/Confirm";
import { UserDisplay } from "./components/User";
import { auth } from "firebase";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AlertProvider>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </AlertProvider>
    </BrowserRouter>
  );
};

const Routes: React.FC = () => {
  const {
    user,
    error,
    route,
    scopes,
    redirectUri,
    handleRedirect,
    handleCancel,
    signOut
  } = useAuthContext();

  if (error) return <ErrorDisplay error={error} onCancel={handleCancel} />;

  if (route === "signup") {
    return (
      <SignUp>
        <LoginLink />
      </SignUp>
    );
  }

  if (route === "login") {
    return (
      <Login>
        <SignUpLink />
      </Login>
    );
  }

  if (route === "confirm") {
    return (
      <Confirm onConfirm={handleRedirect} onCancel={handleCancel}>
        <UserDisplay user={user} onSignOut={signOut} />
        <ConfirmScopes redirectUri={redirectUri} scopes={scopes} />
      </Confirm>
    );
  }

  return <LinearProgress />;
};

export default App;
