import React, { FC } from "react";
import {
  FormSubmit,
  FormValidationProvider,
  validateAllFields,
  validateField
} from "@xyo-network/coin-forms/build/lib/components/Form";
import {
  useSetAlertSuccess,
  useSetAlertError
} from "@xyo-network/coin-forms/build/lib/components/Alert";
import { FormField } from "@xyo-network/coin-forms/build/lib/components/Field";
import { LoadingButton } from "@xyo-network/coin-forms/build/lib/components/Button";
import Layout from "../Layout";
import Box from "@material-ui/core/Box";
import partial from "lodash/partial";
import Link from "@material-ui/core/Link";
import { object, string, InferType } from "yup";
import { useAuthContext } from "../../context/firebase";
import { auth } from "firebase/app";
import "firebase/auth";

type LoginProps = {
  onSuccess?: (credential: auth.UserCredential) => any;
  onError?: (error: Error) => any;
};
type LoginFormSchema = InferType<typeof loginFormSchema>;

const loginFormSchema = object().shape({
  email: string()
    .default("")
    .label("Email")
    .email()
    .required(),
  password: string()
    .default("")
    .label("Password")
    .required()
});

const LoginFormProvider: React.FC = ({ children }) => {
  return (
    <FormValidationProvider<LoginFormSchema>
      initial={loginFormSchema.default()}
      validateFields={partial(validateAllFields, loginFormSchema)}
      validateField={partial(validateField, loginFormSchema)}
    >
      {children}
    </FormValidationProvider>
  );
};

function LoginForm({ onSuccess, onError }: LoginProps) {
  const setSuccess = useSetAlertSuccess();
  const setError = useSetAlertError();
  const [submitting, setSubmitting] = React.useState(false);
  const handleSubmit = async (values: LoginFormSchema) => {
    try {
      setSubmitting(true);
      const credentials = await auth().signInWithEmailAndPassword(
        values.email,
        values.password
      );
      setSuccess("Login successful.");
      if (onSuccess) onSuccess(credentials);
    } catch (e) {
      setError(e.message);
      if (onError) onError(e);
    }
    setSubmitting(false);
  };
  return (
    <FormSubmit<LoginFormSchema> onSubmit={handleSubmit}>
      <LoginFormFields loading={submitting} />
    </FormSubmit>
  );
}

function LoginFormFields({ loading }: { loading: boolean }) {
  return (
    <>
      <FormField
        label="Email"
        name="email"
        margin="normal"
        type="email"
        variant="outlined"
        fullWidth
      />
      <FormField
        label="Password"
        name="password"
        margin="normal"
        type="password"
        variant="outlined"
        fullWidth
      />
      <Box pt={2}></Box>
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        loading={loading}
        fullWidth
      >
        Login
      </LoadingButton>
    </>
  );
}

const Login: FC<LoginProps> = ({ children, ...props }) => {
  return (
    <Layout>
      <LoginFormProvider>
        <LoginForm {...props} />
        {children}
      </LoginFormProvider>
    </Layout>
  );
};

export const SignUpLink = () => {
  const { goToSignUp } = useAuthContext();
  return (
    <Box textAlign="center" mt={1}>
      <Link
        onClick={goToSignUp}
        color="textSecondary"
        style={{ cursor: "pointer" }}
      >
        Do not have an account?
      </Link>
    </Box>
  );
};

export default Login;
