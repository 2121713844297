import React, { FC, useMemo } from "react";
import Box from "@material-ui/core/Box";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Layout from "../Layout";
import DropIcon from "./DropIcon";

export interface ConfirmProps {
  onCancel?: () => any;
  onConfirm?: () => any;
}

export interface ConfirmScopesProps {
  redirectUri: string;
  scopes: string;
}

export const Confirm: FC<ConfirmProps> = ({
  onCancel,
  onConfirm,
  children
}) => {
  return (
    <Layout>
      <Box>
        {/* <Box textAlign="center">
          <CheckCircleOutline color="secondary" style={{ fontSize: 54 }} />
        </Box> */}
        <Box my={2}>{children}</Box>
        <Box display="flex" justifyContent="center" width="100%">
          {onCancel && (
            <Button variant="contained" color="primary" onClick={onCancel}>
              Cancel
            </Button>
          )}
          {onConfirm && (
            <Box ml={1}>
              <Button variant="contained" color="secondary" onClick={onConfirm}>
                Confirm
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export const ConfirmScopes = ({ redirectUri, scopes }: ConfirmScopesProps) => {
  const scopesHash = useMemo(() => {
    return scopes.split(",").reduce((acc, scope) => {
      acc[scope] = true;
      return acc;
    }, {} as { [key: string]: boolean });
  }, [scopes]);
  return (
    <>
      <List>
        <ListItem>
          <Typography color="textSecondary" component="code">
            Allow{" "}
            <Typography color="primary" component="span">
              {redirectUri}
            </Typography>{" "}
            to perform the following actions:
          </Typography>
        </ListItem>
        {scopesHash["drop-opt-in"] && (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <DropIcon style={{ height: 24, width: 24 }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography style={{ whiteSpace: "nowrap" }}>
                Opt In to Geodrops
              </Typography>
            </ListItemText>
          </ListItem>
        )}
      </List>
    </>
  );
};

export default Confirm;
