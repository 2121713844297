import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="35 40 750 290"
    fill="#ee7268"
    style={{ height: 42 }}
  >
    <path d="M176.59,321.89a135,135,0,0,1-96.33-40.34c-53.11-53.79-53.11-141.31,0-195.1a135.2,135.2,0,0,1,192.66,0l-5,5a128.08,128.08,0,0,0-182.73,0c-50.38,51-50.38,134,0,185a128.08,128.08,0,0,0,182.73,0,132,132,0,0,0,20-159l6-3.62a139.18,139.18,0,0,1-21,167.65A134.94,134.94,0,0,1,176.59,321.89Z" />
    <path d="M236.18,306.37a116.31,116.31,0,0,1-83.34-35c-46-46.53-46-122.25,0-168.79A116.41,116.41,0,0,1,250,68.48l-.82,7.06a109.53,109.53,0,0,0-91.36,32.11c-43.21,43.76-43.21,115,0,158.73a109.4,109.4,0,0,0,78.38,32.88l3.31.66-3.18,6.45Z" />
    <path d="M268.62,86.48a96.93,96.93,0,0,0-67.89,29.11c-38.27,38.75-38.27,101.81,0,140.56a96.9,96.9,0,0,0,64.46,29l1-5.88A91.14,91.14,0,0,1,204.86,252c-36-36.44-36-95.74,0-132.18A91.19,91.19,0,0,1,268.62,92.4" />
    <path d="M275.27,114.87c-1.79,1.56-3.54,3.2-5.23,4.91-36,36.44-36,95.74,0,132.18a94.13,94.13,0,0,0,13.43,11.28l-4.73,5.18c-4.35-3.16-9-8.36-12.84-12.27-38.26-38.75-38.26-101.81,0-140.56,1.53-1.54,3.09-3,4.69-4.45" />
    <path d="M281.63,137.08a4.41,4.41,0,0,1-4.13-2.86L268.87,112l-22-8.73a4.52,4.52,0,0,1,0-8.37l22-8.73,8.63-22.26a4.42,4.42,0,0,1,8.26,0h0l8.62,22.26,22,8.73a4.52,4.52,0,0,1,0,8.37l-22,8.73-8.62,22.25A4.41,4.41,0,0,1,281.63,137.08Zm-26-38,16.89,6.72A4.49,4.49,0,0,1,275,108.3l6.63,17.11,6.63-17.1a4.42,4.42,0,0,1,2.51-2.54l16.89-6.72-16.89-6.71a4.45,4.45,0,0,1-2.51-2.54l-6.63-17.11L275,89.8a4.48,4.48,0,0,1-2.51,2.54Z" />
    <path d="M432.89,227.38a120.74,120.74,0,0,1-24,2.72q-10.62,0-17.16-2.91a21.42,21.42,0,0,1-10.1-9.07A41.74,41.74,0,0,1,376.75,204a120.8,120.8,0,0,1-1.36-19.88,123.28,123.28,0,0,1,1.36-20,42.63,42.63,0,0,1,4.92-14.31,21.11,21.11,0,0,1,10-9.06q6.48-2.85,16.84-2.85a124.27,124.27,0,0,1,24.35,2.85l-.39,8.41a134.84,134.84,0,0,0-23.31-2.33q-14.25,0-18.85,8.48t-4.59,28.95a128.3,128.3,0,0,0,.84,16.45,36.05,36.05,0,0,0,3.3,11.2,14.59,14.59,0,0,0,7.19,7.12q4.73,2.15,13.86,2.14a119.69,119.69,0,0,0,21.56-2.33Z" />
    <path d="M560.32,184.65q0,24-7.64,34.7T524.9,230.1q-20.14,0-27.78-11t-7.64-34.65q0-23.62,7.77-35.09t27.59-11.46q19.81,0,27.65,11.39T560.32,184.65Zm-60.61-.26q0,19.68,5.18,28.3t20,8.61q14.83,0,19.94-8.42T550,184.65q0-19.82-5.31-28.88t-19.88-9.07q-14.57,0-19.81,9T499.71,184.39Z" />
    <path d="M623.91,228.81V139.19h10v89.62Z" />
    <path d="M701.09,228.81V139.19h18.65L754.06,220h2.72V139.19h9.84v89.62H748.36L713.52,148h-2.46v80.81Z" />
  </svg>
);
