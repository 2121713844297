import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import CoinLogo from "./Logo";
import React from "react";

export const Header: React.FC = () => {
  return (
    <Box textAlign="center" mb={1}>
      <CoinLogo />
      <Hidden mdUp>
        <Typography variant="h6">
          Earn COIN While Exploring the World!
        </Typography>
      </Hidden>
      <Hidden smDown>
        <Typography variant="h5">
          Earn COIN While Exploring the World!
        </Typography>
      </Hidden>
    </Box>
  );
};

export default Header;
