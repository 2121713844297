import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { darkTheme } from "@xyo-network/coin-material-ui/build/themes/coin";
import "./context/firebase";
import "./index.css";

ReactDOM.render(
  <MuiThemeProvider theme={darkTheme}>
    <CssBaseline />
    <App />
  </MuiThemeProvider>,
  document.getElementById("root")
);
