import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import Header from "../Header";
import React from "react";

const Layout: React.FC<{ header?: ReturnType<typeof Header> }> = ({
  children,
  header = <Header />
}) => {
  return (
    <div style={{ padding: 16, minWidth: 280 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} md={3} lg={4} />
        <Grid item xs={12} sm={8} md={6} lg={4}>
          {header}
          <Fade in={true}>
            <Paper elevation={4}>
              <Box p={2}>{children}</Box>
            </Paper>
          </Fade>
        </Grid>
      </Grid>
    </div>
  );
};

export default Layout;
