import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  FC
} from "react";
import { createCustomToken } from "./token";
import { initializeApp, auth, User } from "firebase/app";
import { useHistory } from "react-router-dom";
import { parse } from "../utils/parse";
import "firebase/auth";

interface IAuthContext {
  redirectUri: string;
  clientId: string;
  scopes: string;
  route: string;
  user: User | null;
  error: string;
  goToLogin: () => any;
  goToSignUp: () => any;
  handleRedirect: () => any;
  handleCancel: () => any;
  signOut: () => any;
}

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
});

const defaultAuthContext: IAuthContext = {
  user: null,
  route: "",
  redirectUri: "",
  clientId: "",
  scopes: "",
  error: "",
  goToLogin: () => console.warn("AuthProvider not initialized"),
  goToSignUp: () => console.warn("AuthProvider not initialized"),
  handleRedirect: () => console.warn("AuthProvider not initialized"),
  handleCancel: () => console.warn("AuthProvider not initialized"),
  signOut: () => console.warn("AuthProvider not initialized")
};

export const AuthContext = createContext(defaultAuthContext);

export const AuthProvider: FC = ({ children }) => {
  const history = useHistory();
  const [state, setState] = useState(defaultAuthContext);

  const goToLogin = () => setState(s => ({ ...s, route: "login" }));
  const goToSignUp = () => setState(s => ({ ...s, route: "signup" }));
  const signOut = async () => {
    try {
      await auth().signOut();
    } catch (e) {
      setState(s => ({ ...s, error: e.message }));
    }
  };
  const handleCancel = () => {
    // window.open(window.location as any, "_self")?.close();
    window.location.href = `${state.redirectUri}`;
  };
  const handleRedirect = async () => {
    try {
      setState(s => ({ ...s, route: "" }));
      const user = auth().currentUser;
      if (!user) throw new Error("Must authenticate to redirect");
      if (!state.redirectUri) throw new Error("Redirect not found");
      const token = await user.getIdToken();
      const customToken = await createCustomToken(state, token);
      window.location.href = `${state.redirectUri}?token=${customToken}`;
    } catch (e) {
      setState(s => ({ ...s, error: e.message }));
    }
  };

  useEffect(() => {
    const { redirectUri = "", clientId = "", scopes = "" } = parse(
      history.location.search.replace(/^\?/, "")
    );

    if (!redirectUri) {
      return setState(s => ({ ...s, error: "Login redirect not provided." }));
    }

    if (!clientId) {
      return setState(s => ({ ...s, error: "Login client not provided." }));
    }

    if (!scopes) {
      return setState(s => ({ ...s, error: "Login scopes not provided." }));
    }

    return auth().onAuthStateChanged(async user => {
      if (user) {
        setState(s => ({
          ...s,
          user,
          scopes,
          redirectUri,
          clientId,
          route: "confirm"
        }));
      } else {
        setState(s => ({
          ...s,
          scopes,
          redirectUri,
          clientId,
          user: null,
          route: "login"
        }));
      }
    });
  }, [history.location.search]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        handleRedirect,
        handleCancel,
        goToLogin,
        goToSignUp,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
