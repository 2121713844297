import React, { SVGProps } from 'react';

export default ({ children, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2700 2670"
      fill="#f89d41"
      {...props}
    >
      <path d="M880 2241 l0 -409 -34 -16 c-19 -9 -114 -67 -210 -129 -279 -178 -505 -320 -548 -344 -73 -41 -71 -36 -65 -158 7 -138 39 -265 107 -422 100 -231 304 -454 547 -597 71 -43 274 -119 386 -146 76 -18 197 -27 197 -15 0 3 -17 24 -37 46 -48 51 -123 152 -118 157 2 2 -7 21 -19 41 -69 108 -157 338 -189 491 -17 81 -43 304 -51 438 l-5 82 48 0 48 0 6 -132 c16 -341 123 -698 280 -928 29 -43 114 -130 127 -130 3 0 26 19 51 42 183 172 337 613 356 1016 l6 132 48 0 48 0 -5 -82 c-8 -134 -34 -357 -51 -438 -33 -155 -147 -444 -204 -516 -5 -6 -7 -13 -4 -15 5 -6 -68 -105 -118 -158 -20 -22 -37 -43 -37 -46 0 -12 121 -3 197 15 112 27 315 103 386 146 242 143 446 366 547 597 67 154 100 283 107 420 5 94 4 110 -12 127 -10 11 -23 20 -28 20 -6 0 -89 51 -186 113 -97 62 -198 126 -226 142 -27 16 -114 70 -192 121 -78 51 -158 100 -177 109 l-36 17 0 409 0 409 -470 0 -470 0 0 -409z m603 -130 c29 -11 40 -44 22 -68 -12 -16 -30 -18 -155 -18 -126 0 -143 2 -155 18 -15 21 -10 46 13 65 19 14 237 17 275 3z m-389 -378 c-15 -27 -66 -111 -114 -188 -48 -77 -106 -176 -130 -219 -58 -106 -99 -149 -178 -188 -57 -28 -78 -33 -163 -36 -73 -3 -110 0 -151 13 -60 20 -133 63 -167 99 l-23 25 53 34 c30 18 61 36 69 39 8 3 88 53 177 111 89 58 183 117 210 131 26 14 116 70 198 125 137 92 154 100 198 101 l49 0 -28 -47z m385 -46 c30 -50 68 -114 85 -141 17 -27 31 -52 31 -55 0 -4 31 -56 68 -116 l68 -109 -19 -26 c-29 -39 -115 -95 -179 -116 -82 -27 -284 -27 -366 0 -64 21 -150 77 -179 116 l-19 26 68 109 c37 60 68 112 68 116 0 3 14 28 31 55 17 27 55 91 85 141 l54 93 75 0 75 0 54 -93z m353 -12 c88 -58 178 -115 200 -126 22 -11 113 -68 202 -126 88 -58 168 -108 176 -111 8 -3 39 -21 69 -39 l53 -34 -23 -25 c-34 -36 -107 -79 -167 -99 -41 -13 -78 -16 -151 -13 -85 3 -106 8 -162 35 -84 42 -115 76 -190 208 -34 61 -92 157 -129 215 -36 58 -81 131 -99 163 l-32 57 46 0 c43 0 61 -10 207 -105z"></path>
    </svg>
  );
};
