import React, { FC } from "react";
import {
  FormSubmit,
  FormValidationProvider,
  validateAllFields,
  validateField
} from "@xyo-network/coin-forms/build/lib/components/Form";
import {
  useSetAlertSuccess,
  useSetAlertError
} from "@xyo-network/coin-forms/build/lib/components/Alert";
import { FormField } from "@xyo-network/coin-forms/build/lib/components/Field";
import { LoadingButton } from "@xyo-network/coin-forms/build/lib/components/Button";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import partial from "lodash/partial";
import Layout from "../Layout";
import { useAuthContext } from "../../context/firebase";
import { createUser } from "../../context/token";
import { object, string, InferType } from "yup";
import { auth } from "firebase/app";
import "firebase/auth";

type SignUpProps = {
  onSuccess?: (credential: auth.UserCredential) => any;
  onError?: (error: Error) => any;
};
type SignUpFormSchema = InferType<typeof signUpFormSchema>;

const signUpFormSchema = object().shape({
  email: string()
    .default("")
    .label("Email")
    .email()
    .required(),
  password: string()
    .default("")
    .label("Password")
    .required(),
  confirmPassword: string()
    .default("")
    .label("Confirm Password")
    .test("password-test", "Password does not match", function(value) {
      return this.parent.password === value;
    })
    .required()
});

const SignUpFormProvider: React.FC = ({ children }) => {
  return (
    <FormValidationProvider<SignUpFormSchema>
      initial={signUpFormSchema.default()}
      validateFields={partial(validateAllFields, signUpFormSchema)}
      validateField={partial(validateField, signUpFormSchema)}
    >
      {children}
    </FormValidationProvider>
  );
};

function SignUpForm({ onSuccess, onError }: SignUpProps) {
  const setSuccess = useSetAlertSuccess();
  const setError = useSetAlertError();
  const [submitting, setSubmitting] = React.useState(false);
  const handleSubmit = async (values: SignUpFormSchema) => {
    try {
      setSubmitting(true);
      const credentials = await auth().createUserWithEmailAndPassword(
        values.email,
        values.password
      );
      if (!credentials.user) {
        throw new Error("User not created");
      }
      const token = await credentials.user.getIdToken();
      await createUser(token);
      setSuccess("Sign up successful.");
      if (onSuccess) onSuccess(credentials);
    } catch (e) {
      setError(e.message);
      if (onError) onError(e);
    }
    setSubmitting(false);
  };
  return (
    <FormSubmit<SignUpFormSchema> onSubmit={handleSubmit}>
      <SignUpFormFields loading={submitting} />
    </FormSubmit>
  );
}

function SignUpFormFields({ loading }: { loading: boolean }) {
  return (
    <>
      <FormField
        label="Email"
        name="email"
        margin="normal"
        type="email"
        variant="outlined"
        fullWidth
      />
      <FormField
        label="Password"
        name="password"
        margin="normal"
        type="password"
        variant="outlined"
        fullWidth
      />
      <FormField
        label="Confirm Password"
        name="confirmPassword"
        margin="normal"
        type="password"
        variant="outlined"
        fullWidth
      />
      <Box pt={2}></Box>
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        loading={loading}
        fullWidth
      >
        Sign Up
      </LoadingButton>
    </>
  );
}

const SignUp: FC<SignUpProps> = ({ children, ...props }) => {
  return (
    <Layout>
      <SignUpFormProvider>
        <SignUpForm {...props} />
        {children}
      </SignUpFormProvider>
    </Layout>
  );
};

export const LoginLink = () => {
  const { goToLogin } = useAuthContext();
  return (
    <Box textAlign="center" mt={1}>
      <Link
        onClick={goToLogin}
        color="textSecondary"
        style={{ cursor: "pointer" }}
      >
        Already have an account?
      </Link>
    </Box>
  );
};

export default SignUp;
