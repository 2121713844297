import React from "react";
import Box from "@material-ui/core/Box";
import CancelOutlined from "@material-ui/icons/CancelOutlined";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Layout from "../Layout";

const ErrorDisplay = ({
  error,
  onCancel
}: {
  error: string;
  onCancel?: () => any;
}) => {
  return (
    <Layout>
      <Box display="flex" flexDirection="column" alignItems="center">
        <CancelOutlined color="primary" style={{ fontSize: 54 }} />
        <Box my={2}>
          <Typography color="textSecondary">{error}</Typography>
        </Box>
        <Box display="flex" justifyContent="center" width="100%">
          {onCancel && (
            <Button variant="contained" color="primary" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default ErrorDisplay;
