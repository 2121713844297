const api = process.env.REACT_APP_BOSS_API;

export async function createUser(token: string) {
  const res = await fetch(`${api}`, {
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
        getOrCreateCoinUser {
          ownerUID
        }
      }`
    }),
    headers: {
      "X-Auth-Token": token,
      "Content-Type": "application/json"
    }
  });
  const result = await res.json();
  const user = result && result.data && result.data.getOrCreateCoinUser;
  if (!user) {
    throw new Error("User not created");
  }
  return user;
}

export async function createCustomToken(
  {
    clientId,
    redirectUri,
    scopes
  }: {
    clientId: string;
    redirectUri: string;
    scopes: string;
  },
  token: string
): Promise<string> {
  const res = await fetch(`${api}`, {
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
        createScopedToken(clientId: "${clientId}", redirectUri: "${redirectUri}", scopes: "${scopes}")
      }`
    }),
    headers: {
      "X-Auth-Token": token,
      "Content-Type": "application/json"
    }
  });
  const result = await res.json();
  const tokenId = result && result.data && result.data.createScopedToken;
  if (!tokenId) {
    throw new Error("Custom token not created");
  }
  return tokenId;
}
